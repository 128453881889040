import React, { useState } from "react";
import "./Header.css";
import "./About.css";
import "./Banner.css";
import "./Service.css";
import "./Footer.css";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, Element } from "react-scroll";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Helmet>
        <title>Roi Mantra</title>
      </Helmet>
      <div className="nav-main">
        <div className="Container main-nav">
          <Link to="/home">
            <img
              src="https://ik.imagekit.io/xjjzkwxra/ROI-MANTRA/WhatsApp_Image_2024-05-16_at_3.20.39_PM-removebg-preview.png?updatedAt=1715854903056"
              loading="lazy"
              className="nav-logo"
              alt="logo"
            />
          </Link>
          <ul
            className={isOpen ? "nav-links-mobile" : "nav-links"}
            onClick={() => setIsOpen(false)}
          >
            <li className="nav-li">
              <Link
                to="section1"
                smooth={true}
                duration={-200}
                active
                className="nav-home"
              >
                Home
              </Link>
            </li>

            <li className="nav-li">
              <Link
                to="section2"
                smooth={true}
                duration={-200}
                className="nav-home"
              >
                About Us
              </Link>
            </li>
            <li className="dropdowns nav__menu-item nav-li">
              <Link
                to="section3"
                smooth={true}
                duration={-200}
                className="nav-home"
              >
                Services
              </Link>
            </li>

            <li className="nav-li">
              <Link to="section4"
                smooth={true}
                duration={-200}
                className="nav-home">
                Contact Us
              </Link>
            </li>
          </ul>
          <button
            className="mobile-menu-icon"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? (
              <FontAwesomeIcon icon={faBars} className="nav-icon" />
            ) : (
              <FontAwesomeIcon icon={faBars} className="nav-icon" />
            )}
          </button>
        </div>
      </div>
      <div className="whatsapp-chat">
      <a href="https://wa.me/8475888222" target="_blank" rel="noopener noreferrer">
            <img
              src="https://ik.imagekit.io/xjjzkwxra/ROI-MANTRA/whatsapp-873316_640.png?updatedAt=1715775097912"
              loading="lazy"
              alt="whatsapp"
            />
          </a>
        </div>
      <Element name="section1">
        <div className="banner">
          <div className="banner-title Container flex">
            <div className="banner-text">
              {/* <h3 className="banner-text-h3">WE CONVERT LEAD INTO REALTY</h3> */}
              <h1 className="banner-text-h1">
                We specialize in turning leads into reality, offering top-notch
                services tailored to your company's needs.
              </h1>

              <div className="btn-box">
                <Link to="section2" smooth={true} duration={-200}>
                  <button className="banner-btn">About Us </button>
                </Link>
                <Link to="section3" smooth={true} duration={-200}>
                  <button className="banner-btn1">Our Services</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Element>

      <Element name="section2">
        

        <section className="about-main">
          <div className="Container about-box">
            <div className="about-img">
              <img
                src="https://ik.imagekit.io/xjjzkwxra/ROI-MANTRA/About%20Us%20(1).jpg?updatedAt=1715776110764"
                width="300px"
                height="300px"
              />
            </div>
            <div className="about-content">
              <h2>About Us</h2>
              <p>
                At the heart of our organization lies a fervent dedication to
                revolutionizing your digital marketing endeavors. We pride
                ourselves on being a versatile agency poised to deliver
                unparalleled outcomes for your business. Through a comprehensive
                suite of services spanning bulk SMS, interactive voice response
                (IVR), voice calls, WhatsApp integration, and bespoke lead
                generation tactics, we endeavor to craft ingenious solutions
                finely attuned to your distinct requirements. <br/> <br/>Our cadre of
                seasoned professionals is driven by a relentless commitment to
                empowering you to forge robust connections with your target
                demographics, fostering enduring relationships and propelling
                sustained expansion. By joining forces with us, you gain access
                to a wealth of expertise and cutting-edge methodologies aimed at
                unleashing the full spectrum of possibilities inherent in your
                marketing strategies. <br/> <br/> Whether you seek to amplify brand
                visibility, enhance customer engagement, or cultivate a loyal
                consumer base, our multifaceted approach ensures that every
                facet of your marketing campaign is meticulously optimized for
                success. Embrace the opportunity to transcend conventional
                boundaries and embark on a transformative journey towards
                unprecedented growth and prosperity with our unwavering support
                and guidance. Let us be your catalyst for realizing the true
                potential of your marketing endeavors.
              </p>
            </div>
          </div>
        </section>
      </Element>

      <Element name="section3">
        <div className="service-main">
          <div class=" Container ">
            <div className="service-header">
              <h1>Our Services</h1>
            </div>
            <section class="articles">
              <article>
                <div class="article-wrapper">
                  <figure>
                    <img
                      src="https://ik.imagekit.io/xjjzkwxra/ROI-MANTRA/10.jpg?updatedAt=1715939221641"
                      alt=""
                    />
                  </figure>
                  <div class="article-body">
                    <h2>BULK SMS</h2>
                    <p>
                      Our Bulk SMS services involve sending numerous text
                      messages to multiple mobile devices at once. This method
                      is frequently employed for various purposes such as
                      marketing, notifications, reminders, alerts, and general
                      communication needs. Businesses, organizations, and
                      individuals often utilize this service to reach their
                      target audience effectively.
                    </p>
                  </div>
                </div>
              </article>
              <article>
                <div class="article-wrapper">
                  <figure>
                    <img
                      src="https://ik.imagekit.io/xjjzkwxra/ROI-MANTRA/WHATSAPP%20%20MARKETING%20(1).jpg?updatedAt=1715773081175"
                      alt=""
                    />
                  </figure>
                  <div class="article-body">
                    <h2>WHATSAPP MARKETING</h2>
                    <p>
                      We offer WhatsApp account verification services, which
                      come in two types: Verified and Unverified. Verified
                      Business Accounts sport a special green checkmark badge,
                      indicating that WhatsApp has authenticated them. On the
                      other hand, the Unverified badge with a green checkmark
                      denotes that the account hasn't undergone WhatsApp's
                      verification process.
                    </p>
                  </div>
                </div>
              </article>
              <article>
                <div class="article-wrapper">
                  <figure>
                    <img
                      src="https://ik.imagekit.io/xjjzkwxra/ROI-MANTRA/9.jpg?updatedAt=1715939221690"
                      alt=""
                    />
                  </figure>
                  <div class="article-body">
                    <h2>IVR SERVICE</h2>
                    <p>
                      Amidst the pandemic, businesses have adjusted customer
                      care tactics for reliability. Roi Mantra facilitates
                      outreach via automated IVR calls, streamlining customer
                      engagement with pre-recorded messages.
                    </p>
                  </div>
                </div>
              </article>
              <article>
                <div class="article-wrapper">
                  <figure>
                    <img
                      src="https://ik.imagekit.io/xjjzkwxra/ROI-MANTRA/11.jpg?updatedAt=1715939221296"
                      alt=""
                    />
                  </figure>
                  <div class="article-body">
                    <h2>VOICE CALL</h2>
                    <p>
                      For voice-related needs, explore the option of integrating
                      cloud-based services like Google Cloud Speech-to-Text or
                      Amazon Transcribe for precise speech recognition.
                      Customize the solution to align with your specific
                      application requirements, ensuring effortless integration
                      and user-friendly functionality.
                    </p>
                  </div>
                </div>
              </article>
              <article>
                <div class="article-wrapper">
                  <figure>
                    <img
                      src="https://ik.imagekit.io/xjjzkwxra/ROI-MANTRA/8.jpg?updatedAt=1715939221624"
                      alt=""
                    />
                  </figure>
                  <div class="article-body">
                    <h2>DIGITAL MARKETING</h2>
                    <p>
                      Digital marketing encompasses a diverse set of strategies
                      designed to boost the visibility of products, services, or
                      brands across digital platforms. These strategies include
                      content marketing, social media marketing, email
                      marketing, pay-per-click (PPC) advertising, and analytics,
                      all working in tandem with search engine optimization
                      (SEO).
                    </p>
                  </div>
                </div>
              </article>
              <article>
                <div class="article-wrapper">
                  <figure>
                    <img
                      src="https://ik.imagekit.io/xjjzkwxra/ROI-MANTRA/13.jpg?updatedAt=1715939221587"
                      alt=""
                    />
                  </figure>
                  <div class="article-body">
                    <h2>LEAD GENERATION</h2>
                    <p>
                      Generating leads stands as a critical pillar within any
                      online marketing approach. It serves as the initial stage
                      in transforming a visitor into a customer, marking the
                      pivotal beginning of a business-consumer relationship.
                      Therefore, as a business entity, maximizing every chance
                      to convert prospects into paying customers becomes
                      imperative for sustained growth and success.
                    </p>
                  </div>
                </div>
              </article>
            </section>
          </div>
        </div>
      </Element>

      <Element name="section4">
      <div className="footer">
        <div className="Container">
          <div class="subscribe">
            <div class="subscribe__img">
              <img src="https://ik.imagekit.io/xjjzkwxra/ROI-MANTRA/WhatsApp_Image_2024-05-16_at_3.20.39_PM-removebg-preview.png?updatedAt=1715854903056" />
            </div>

            <p>
              Reg Office : Sarai Mohar Singh gali no. 2 Kila road, Khurja,
              Bulandshahar, Uttar Pradesh, 203131
            </p>
            <p class="subscribe__copy">GSTIN: 09ASCPG1000E1ZM</p>
            <Link
              to="javascript:void(0)"
              onClick={() =>
                (window.location = "mailto:goyalmohit460@gmail.com")
              }
            >
              <p>Email: goyalmohit460@gmail.com</p>
            </Link>
            <Link to="tel:+91-8475888222">
              <p>Contact: +91 8475888222</p>
            </Link>
            <div class="notice">
              <span class="notice__copy">
                Copyright @2024 &nbsp;&nbsp; Roi Mantra
              </span>
            </div>
          </div>
        </div>
      </div>
      </Element>
    </>
  );
};

export default Header;
