import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ScrollToTop from './common/components/header/ScrollToTop';
import Header from './common/pages/Header';
import GoToTop from './common/components/header/GoToTop';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <BrowserRouter>
   <ScrollToTop />
   <Header />
     <Routes>
       <Route path='/' exact element={<App/>}/>
       <Route path='/home' exact element={<App/>}/>
     </Routes>
   <GoToTop/>
   </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
